// import "./modules/font-awesome";

function downloadJSAtOnload() {
  // Script 1
  const scriptOne = document.createElement("script");
  scriptOne.src = "https://use.fontawesome.com/releases/v5.1.0/js/all.js";
  document.body.appendChild(scriptOne);
  // Script 2
  // const scriptTwo = document.createElement("script");
  // scriptTwo.src =
  //   "https://static.zdassets.com/ekr/snippet.js?key=b9bcb4e6-39e1-43ea-b884-2d8176b32807";
  // document.body.appendChild(scriptTwo);
  // // Script 3
  // const scriptThree = document.createElement('script');
  // scriptThree.src = '/static/js/mailchimp.js';
  // document.body.appendChild(scriptThree);
}

// if (window.addEventListener)
//   window.addEventListener("load", downloadJSAtOnload, false);
// else if (window.attachEvent) window.attachEvent("onload", downloadJSAtOnload);
// else window.onload = downloadJSAtOnload;

const images = document.querySelectorAll("img.lazy");

const options = {
  // If the image gets within 50px in the Y axis, start the download.
  root: null, // Page as root
  rootMargin: "0px",
  threshold: 0.1
};

const fetchImage = url => {
  // console.log(url);
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = resolve;
    image.onerror = reject;
  });
};

const loadImage = image => {
  const src = image.dataset.src;
  fetchImage(src).then(() => {
    console.log(src);
    image.src = src;
  });
};

const handleIntersection = (entries, observer) => {
  entries.forEach(entry => {
    if (entry.intersectionRatio > 0) {
      console.log(entry.intersectionRatio);
      loadImage(entry.target);
    }
  });
};

// The observer for the images on the page
if ("IntersectionObserver" in window) {
  // Observer code
  const observer = new IntersectionObserver(handleIntersection, options);
  images.forEach(img => {
    observer.observe(img);
  });
} else {
  // IO is not supported.
  // Just load all the images
  Array.from(images).forEach(image => loadImage(image));
}

jQuery(document).ready($ => {
  $(".navicon-button").click(function() {
    $(this).toggleClass("open");

    console.log("nav click");
  });
});


 jQuery(document).ready($ => {


  $(".navbar-toggler").click(function() {
    $("#nav").addClass("nav-light");
    $("#nav").removeClass("nav-transparent");
    console.log("nav click");
  });
});


// Code to triggert when user scrolled 50% of page content
// var body = document.body;
// var element = document.getElementById("id");
// //Update DOM on scroll
// document.addEventListener("scroll", function() { 
//     var scrollAmt = window.pageYOffset || document.documentElement.scrollTop;
// 	if(window.innerHeight/2 >= scrollAmt)
// 	 	element.style.display = "none"; 
//   	else	
// 		element.style.display = "block";


// import "bootstrap";

// import "./modules/lazyload";
